import { useStaticQuery, graphql } from "gatsby";
import React from "react";

const CvPage = () => {
    const data = useStaticQuery(graphql`
        query MyCvQuery {
            file(relativePath: {eq: "Abbiasov_CV.pdf"}) {
                publicURL
                name
            }
        }
    `);
    return <head>
        <meta http-equiv="refresh" content={"0; URL=" + data.file.publicURL} />
        <title>Curriculum Vitae</title>
    </head>
  };
  
  export default CvPage;